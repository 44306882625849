@import url(https://fonts.googleapis.com/css?family=Gugi);
.signin {
  background-color: #F5F5F5;
}

.checkbox {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.inputcheckbox {
  display: none;
}


.logouser {
  background-color: #FFF;
  border-radius: 50%;
  border: 1px solid #FFF;
}

.signin-form-header {
  background-color: #F5F5F5;
  font-family: 'Gugi', cursive;
}

.signin-form-header > h6 {
  font-size: 1.6em;
}

.signin-form-body {
  background-color: #FFF;
  border: 1px solid #DBDBDB;
  border-radius: 1px;
  padding: 1em;
  box-sizing: border-box;
}

.newtask {
  min-height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  padding: 1em;
  background-color: #F5F5F5;
}

.newtask-aside {
  width: 25%;
  box-sizing: border-box;
}

.newtask-body {
  box-sizing: border-box;
  margin-top: 3em;
  margin-bottom: 1em;
  width: 60%;
  margin: 1.5em;
}

.navegador {
  background-color: #FFF;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  color: #222;
}

.navegador-left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  padding: .5em;
  box-sizing: border-box;
}

.navegador-rigth {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding: .5em;
  box-sizing: border-box;
}

.logo-img {
  height: 40px;
  width: 40px;
}

.logo-figcaption {
  margin-left: .25em;
  font-size: 1.6em;
  color: #FFF;
}


.link {
  margin: .5em;
}

.navegador-mobile{
  display: none;
}

.taskitem-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media only screen and (max-width: 620px) {

  .newtask {
    width: 100%;
  }

  .newtask-body {
    width: calc(100% - 2em);
    margin: 1em;
  }

  .newtask-aside {
    width: calc(100% - 2em);
    margin: 1em;
  }

  .navegador {
    max-width: 100%;
    overflow-x: scroll;
  }

  .checkboximg {
    -webkit-order: 2;
            order: 2;
  }

  .checkboxtitle {
    -webkit-order: 1;
            order: 1;
  }

  .newtask-body {
    -webkit-order: 1;
            order: 1;
  }

  .newtask-aside {
    width: 100%;
    -webkit-order: 2;
            order: 2;
  }

}

.roadmap {
  background-color: #F5F5F5;
  padding: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
}

.signin-form-header h6 {
  font-weight: bold;
}

.roadmap-body {
  min-width: calc(60% - 2em);
  margin: 1em;
}

.roadmap-aside {
  min-width: calc(20% - 2em);
  margin: 1em;
}

.nav-aside {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: 2em;
  padding: 1em;
  box-sizing: border-box;
}
.nav-aside-item {
  margin: .25em;
  padding: .5em;
  border: 1px solid #DDD;
  background-color: #FFF;
}

.pie {
  background-color: #F5F5F5;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 1em;
}

.pie-body {
  width: 100%;
  padding: .5em;
  text-align: center;
}

.vehicles {
  max-width: 80%;
  min-height: 100vh;
}

.monitor {
  min-height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  background-color: #DBDBDB;
}

.monitor-header {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  text-align: center;
}

.monitor-body {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
  
}

.monitor-body-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 1em;
}

.monitor-body-item-item {
  background-color: #FFF;
  box-sizing: border-box;
  padding: 1em;
  border-bottom: 1px solid #DBDBDB;
}

.monitor-body-item-body {
  list-style: none;
  box-sizing: border-box;
  padding: 1em;
}

.monitor-body-item-item-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  box-sizing: border-box;
}

.monitor-body-item-item-header > h5 {
  width: 90%;
  box-sizing: border-box;
  margin-right: 1em;
}

.monitor-body-item-item-header > small {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 1px solid white;
}

.completada {
  background-color: #5cb85c;
}

.completada_sin_terminar {
  background-color: #5bc0de;
}

.retrasada {
  background-color: #d9534f;
}

.pendiente {
  background-color: #f0ad4e;
}

.cancelada {
  background-color: #292b2c;
}

.horaria {
  background-color: #FFF;
}

.ahoraria {
  background-color: #292b2c;
}

.monitor-footer {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.monitor-footer-item {
  padding: .5em;
  margin: 1em;
}

.roadmap-list-item{
  color: #FFF;
}

.roadmap-list-item-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.roadmap-list-item-body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.roadmap-list-item-body-right {
  margin-left: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  max-width: 300px;
}

.roadmap-list-item-body-left {
  max-width: 40%;
}

@media only screen and (max-width: 640px) {
  .roadmap-list-item-body-left {
    max-width: 100%;
  }
  .roadmap-list-item-body-right{
    max-width: 100%;
  }
}
.CalendarDay__selected_span {
  background: #82e0aa; /*background*/
  color: black; /*text*/
  border: 1px solid $light-red; /*default styles include a border*/
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $dark-red;
  color: white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}


